/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.grecaptcha-badge { visibility: hidden; }

form mat-form-field {
    width: 350px;
}

.mat-grid-tile-content {
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

/**
Upgrading to angular 15+, change the below to look like.  This means we don't need to use !important as these css variables will be available to us to modify:

.mat-mdc-snack-bar-container {
    & .blue-snackbar {
      --mdc-snackbar-container-color: #2196f3;
    }
  }

*/

.blue-snackbar {
    background-color: #2196f3 !important;
}

.green-snackbar {
    background-color: #51d331 !important;
}

.red-snackbar {
    background-color: #f32121 !important;
}

.orange-snackbar {
    background-color: #f38021 !important;
}