@use '@angular/material' as mat;
@include mat.core();

// Define a dark theme
$dark-theme: mat.define-theme(
    (
        color: (
            theme-type: dark,
            primary: mat.$azure-palette
        ),
        density: (
            scale: 0
        )
    )
);

// Define a light theme
$light-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$azure-palette
        ),
        density: (
            scale: 0
        )
    )
);

// Apply the light theme by default
html {
    @include mat.all-component-themes($light-theme);
}

body.dark-theme {
    @include mat.all-component-colors($dark-theme);
}
